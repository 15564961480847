import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Grid } from '@material-ui/core'
import { useApi } from '@wedding-site/client/components/shared/feat-api'
import { getPage } from '@wedding-site/client/apis/contentful/pages'
import i18n from 'i18next'
import { Loading } from '@wedding-site/client/components/shared/ui-loading'
import { setBackground } from '@wedding-site/client/utils/set-background'
import { Article } from '@wedding-site/client/components/shared/feat-article'
import style from "./style.module.sass"

const pageId = "6dUanCVX76bcvJZkoDtvRh"

export const OurStory: React.FC = (): JSX.Element => {

  const callPage = React.useCallback(() => getPage({ pageId }), [])
  const { running, data: page } = useApi({ callFunction: callPage, initialValue: null })

  if (running)
    <Loading message={i18n.t('app:loading')} />

  page?.fields.background ? setBackground(page.fields.background.fields.file.url) : null
  return (
    <Grid container>
      <Grid item xs={12} md={8} lg={9} className={style.image} />
      <Grid item xs={12} md={4} lg={3} className={style.content}>
        <h1>{page?.fields.header}</h1>
        {page?.fields.introduction ? (<ReactMarkdown>{page?.fields.introduction}</ReactMarkdown>) : null}
        {page?.fields.articles?.map((article, index) => <Article key={index} header={false} article={article.fields} />)}
      </Grid>
    </Grid>
  )
}