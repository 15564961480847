import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from '@material-ui/core'
import { Context } from '@wedding-site/client/components/shared/feat-context'
import style from './style.module.sass'

export const Home: React.FC= (): JSX.Element => {
  const household = React.useContext(Context)

  const [open, setOpen] = React.useState(false)
  const [launch, setLaunch] = React.useState(true)
  function handleClick() {
    setLaunch(false)
    open ? setOpen(false) : setOpen(true)
  }
  return (
    <div className={`${style.content} ${!launch ? open ? style.envelopOpen : style.envelopClosed : null}`}>
      <div className={style.logo} />
      <div className={style.envelop} onClick={() => { handleClick() }}>
        <div className={style.flap}>
          <span>Klik hier</span>
        </div>
        <div className={style.card}>
          <div className={style.invite}>
            Je bent uitgenodigd!
          </div>
          <div className={style.name}>
            {household?.displayName}
          </div>
          <Link to='/rsvp'>
            <Button className={style.button}>Beantwoorden</Button>
          </Link>
        </div>
      </div>
    </div>
  )
}