import {StitchAppClient, RemoteMongoClient, Stitch, UserApiKeyCredential} from 'mongodb-stitch-browser-sdk'

interface InitResponse {
  realmApp: StitchAppClient
  mongoDb: RemoteMongoClient
}
// Create the Realm app (if not already created)
export const initializeRealmApp = async (): Promise<InitResponse> => {
  const realmAppId = 'weddingsite-nsgpg'
  const realmApp = Stitch.hasAppClient(realmAppId) ? Stitch.getAppClient(realmAppId) : Stitch.initializeAppClient(realmAppId)
  if (!realmApp.auth.isLoggedIn) {
    // eslint-disable-next-line no-console
    console.log('Logging in to Realm')
    await realmApp.auth.loginWithCredential(new UserApiKeyCredential("TbUXLb1VMawSi1glcEbFHj7XU7yyd2ETcdeNfxqbVBsE6Iv1yBUpqQ5VN0Wal3uY"))
  }

  return {realmApp, mongoDb: realmApp.getServiceClient(RemoteMongoClient.factory, 'mongodb-atlas')}
}
