// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseError = (error: any): string => {
  let msg = ''
  msg = error.errors || error.statusText || error.body || error.message || error

  // Generic API Management response
  if (Array.isArray(msg) && msg[0].message) {
    msg = `${msg[0].message}`
  }

  // make sure we always pass a string
  if (typeof msg !== 'string') {
    msg = JSON.stringify(msg)
  }
  return msg
}
