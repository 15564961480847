import React from 'react'
import { parseError } from '@wedding-site/common/error-handling/parse-error'

interface Status<T> {
  running: boolean
  error: string
  data: T
}

interface Params<T> {
  callFunction: () => Promise<T>
  initialValue: T
}
export const useApi = <T extends unknown>({ callFunction, initialValue }: Params<T>) => {
  const [status, setStatus] = React.useState<Status<T>>({ running: false, error: '', data: initialValue })

  React.useEffect(() => {
    const makeRequest = async () => {
      setStatus(prevState => ({ ...prevState, running: true, error: '' }))
      try {
        setStatus({ running: false, error: '', data: await callFunction() })
      } catch (err) {
        setStatus(prevState => ({ ...prevState, running: false, error: parseError(err) }))
      }
    }
    makeRequest()
  }, [callFunction])

  return { ...status }
}
