import {DatabaseName, DbCollections} from '@wedding-site/common/database/types'
import {StitchClientErrorCode} from 'mongodb-stitch-browser-sdk'
import {initializeRealmApp} from './init'

interface InsertOneParams<T> {
  dbName: DatabaseName
  col: DbCollections
  data: T
}
export const insertOne = async <T>({dbName, col, data}: InsertOneParams<T>): Promise<void> => {
  const {mongoDb} = await initializeRealmApp()
  try {
    await mongoDb.db(dbName).collection<T>(col).insertOne(data)
  } catch (err) {
    if (err.code === StitchClientErrorCode.MustAuthenticateFirst) {
      await mongoDb.db(dbName).collection<T>(col).insertOne(data)
    } else {
      throw err
    }
  }
}

interface InsertManyParams<T> {
  dbName: DatabaseName
  col: DbCollections
  data: T[]
}
export const insertMany = async <T>({dbName, col, data}: InsertManyParams<T>): Promise<void> => {
  const {mongoDb} = await initializeRealmApp()
  try {
    await mongoDb.db(dbName).collection<T>(col).insertMany(data)
  } catch (err) {
    if (err.code === StitchClientErrorCode.MustAuthenticateFirst) {
      await mongoDb.db(dbName).collection<T>(col).insertMany(data)
    } else {
      throw err
    }
  }
}
