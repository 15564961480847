import {DatabaseName, DbCollections} from '@wedding-site/common/database/types'
import {Filter} from 'mongodb'
import {StitchClientErrorCode} from 'mongodb-stitch-browser-sdk'
import {RemoteCountOptions} from 'mongodb-stitch-core-services-mongodb-remote'
import {initializeRealmApp} from './init'

interface CountParams<T> {
  dbName: DatabaseName
  col: DbCollections
  query?: Filter<T>
  options?: RemoteCountOptions | undefined
}
export const count = async <T>({col, dbName, options, query}: CountParams<T>): Promise<number> => {
  const {mongoDb} = await initializeRealmApp()
  try {
    const data = await mongoDb.db(dbName).collection<T>(col).count(query, options)
    return data
  } catch (err) {
    if (err.code === StitchClientErrorCode.MustAuthenticateFirst) {
      const data = await mongoDb.db(dbName).collection<T>(col).count(query, options)
      return data
    }
    throw err
  }
}
