import { contentfulClient, getEntries, getEntry } from '@wedding-site/client/apis/contentful/client'
import { Location } from '@wedding-site/common/types/locations'

interface getLocation extends getEntry {
    locationId: string
}

export const getLocation = ({ locationId }: getLocation) => contentfulClient.getEntry<Location>(locationId)

interface getLocations extends getEntries {
    locationIds?: string[]
}

export const getLocations = ({ tags, locationIds: entryIds, select, orderBy }: getLocations) => contentfulClient.getEntries<Location>({
    content_type: 'location',
    'metadata.tags.sys.id[in]': tags?.join(),
    'sys.id[in]': entryIds?.join(),
    select: select,
    order: orderBy
})