import {DatabaseName, DbCollections} from '@wedding-site/common/database/types'
import {Filter} from 'mongodb'
import {StitchClientErrorCode} from 'mongodb-stitch-browser-sdk'
import {initializeRealmApp} from './init'

interface DeleteOneParams<T> {
  dbName: DatabaseName
  col: DbCollections
  query: Filter<T>
}
export const deleteOne = async <T>({dbName, col, query}: DeleteOneParams<T>): Promise<void> => {
  const {mongoDb} = await initializeRealmApp()
  try {
    await mongoDb.db(dbName).collection<T>(col).deleteOne(query)
  } catch (err) {
    if (err.code === StitchClientErrorCode.MustAuthenticateFirst) {
      await mongoDb.db(dbName).collection<T>(col).deleteOne(query)
    } else {
      throw err
    }
  }
}

interface DeleteManyParams<T> {
  dbName: DatabaseName
  col: DbCollections
  query: Filter<T>
}
export const deleteMany = async <T>({dbName, col, query}: DeleteManyParams<T>): Promise<void> => {
  const {mongoDb} = await initializeRealmApp()
  try {
    await mongoDb.db(dbName).collection<T>(col).deleteMany(query)
  } catch (err) {
    if (err.code === StitchClientErrorCode.MustAuthenticateFirst) {
      await mongoDb.db(dbName).collection<T>(col).deleteMany(query)
    } else {
      throw err
    }
  }
}
