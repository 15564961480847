import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Box } from '@material-ui/core'
import { BackButton } from '@wedding-site/client/components/feat-navigation'
import { useApi } from '@wedding-site/client/components/shared/feat-api'
import { getPage } from '@wedding-site/client/apis/contentful/pages'
import i18n from 'i18next'
import { Loading } from '@wedding-site/client/components/shared/ui-loading'
import { Article } from '@wedding-site/client/components/shared/feat-article'
import style from "./style.module.sass"

const pageId = "1EncJ1vmS69hXHqcIQVwhu"

export const WeddingCode: React.FC = (): JSX.Element => {
  const callPage = React.useCallback(() => getPage({ pageId }), [])
  const { running, data: page } = useApi({ callFunction: callPage, initialValue: null })

  if (running)
    <Loading message={i18n.t('app:loading')} />

  return (
    <Box className={style.content}>
      <BackButton />
      <h1>{page?.fields.header}</h1>
      {page?.fields.introduction ? (<ReactMarkdown>{page?.fields.introduction}</ReactMarkdown>) : null}
      {page?.fields.articles?.map((article, index) => <Article key={index} article={article.fields} />)}
    </Box>
  )
}