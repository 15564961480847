import { Household } from '@wedding-site/common/types/households'
import { getHousehold } from '@wedding-site/client/apis/realm/households'

interface getHouseholdUsingCode {
    code: string
	submitted: boolean
}

export const getHouseholdUsingCode = async ({ code, submitted }: getHouseholdUsingCode): Promise<Household | null> => {
	if (!submitted) return null
	return getHousehold({ code })
} 