import React from 'react'
import { Link as ReactLink } from 'react-router-dom';
import { useApi } from '@wedding-site/client/components/shared/feat-api'
import { Page } from '@wedding-site/common/types/pages';
import { getPages } from '@wedding-site/client/apis/contentful/pages'
import i18n from 'i18next'
import { Loading } from '@wedding-site/client/components/shared/ui-loading'
import { Box } from '@material-ui/core'
import style from "./style.module.sass";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

const query = {
  tags: ['menu'],
  select: [
    'fields.header',
    'fields.description',
    'fields.path',
    'fields.icon'
  ],
  orderBy: 'fields.orderId'
}

const Link: React.FC<Page> = (page): JSX.Element => (
  <ReactLink to={page.path}>
    <div className={style.item}>
      <img className={style.iconLeft} src={page.icon?.fields.file.url} />
      <div className={style.data}>
        <div className={style.title}>{page.header}</div>
        <div className={style.detail}>{page.description}</div>
      </div>
      <FontAwesomeIcon className={style.iconRight} icon={faArrowRight} />
    </div>
  </ReactLink>
)

export const Menu: React.FC = (): JSX.Element => {
  const callPages = React.useCallback(() => getPages(query), [])
  const { running, data: pages } = useApi({ callFunction: callPages, initialValue: null })

  if (running)
    <Loading message={i18n.t('app:loading')} />

  return (
    <Box className={style.content}>
      <div className={style.heading}>
        <h1>Meer</h1>
      </div>
      <div className={style.list}>
        {pages?.items.map((item, index) => <Link key={index} {...item.fields}/>)}
      </div>
    </Box>
  )
}