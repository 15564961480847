import {StitchClientErrorCode} from 'mongodb-stitch-browser-sdk'
import {initializeRealmApp} from './init'

export const callFunction = async <T>(functionName: string, parameters: unknown[]): Promise<T> => {
  const {realmApp} = await initializeRealmApp()
  try {
    const data = await realmApp.callFunction(functionName, parameters)
    return data
  } catch (err) {
    if (err.code === StitchClientErrorCode.MustAuthenticateFirst) {
      const data = await realmApp.callFunction(functionName, parameters)
      return data
    }
    throw err
  }
}
