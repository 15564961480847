import { faHeart, faHome, faEnvelope, faCalendar, faBars } from '@fortawesome/free-solid-svg-icons'
import { LinkProps } from './types'

export const links: LinkProps[] = [
    {
        name: "Home",
        target: "/",
        icon: faHome
    },
    {
        name: "Ons verhaal",
        target: "/our-story",
        icon: faHeart
    },
    {
        name: "RSVP",
        target: "/rsvp",
        icon: faEnvelope
    },
    {
        name: "Programma",
        target: "/program",
        icon: faCalendar
    },
    {
        name: "Meer",
        target: "/more",
        icon: faBars
    }
]