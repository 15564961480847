import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Form, Input, Switch } from 'antd'
import { Button, Grid, Box } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import i18n from 'i18next'
import { BackButton } from '@wedding-site/client/components/feat-navigation'
import { Loading } from '@wedding-site/client/components/shared/ui-loading'
import { useApi } from '@wedding-site/client/components/shared/feat-api'
import { Household, Guest } from '@wedding-site/common/types/households'
import { Context } from '@wedding-site/client/components/shared/feat-context'
import { updateHousehold, updateHouseholdProps } from '@wedding-site/client/apis/realm/households'
import { getHouseholdUsingCode } from './api'
import { MembersProps, GuestsProps } from './types'
import { setBackground } from '@wedding-site/client/utils/set-background'
import { getPage } from '@wedding-site/client/apis/contentful/pages'
import { Article } from '@wedding-site/client/components/shared/feat-article'
import style from './style.module.sass'

const Members: React.FC<MembersProps> = ({ household, setHousehold, article }: MembersProps): JSX.Element => {
  const [stateHousehold, setStateHousehold] = React.useState<updateHouseholdProps>({ ...household, updated: false })

  const callFunction = React.useCallback(() => updateHousehold(stateHousehold), [stateHousehold])
  const { } = useApi<void>({ callFunction, initialValue: undefined })

  const handleChange = (member: Guest) => {
    // setStateHousehold({ ...stateHousehold, members: stateHousehold.members.map(m => m.displayName === member.displayName ? { ...member, acceptedInvitation: !m.acceptedInvitation } : m), updated: true })
    // setHousehold({ ...stateHousehold, members: stateHousehold.members.map(m => m.displayName === member.displayName ? { ...member, acceptedInvitation: !m.acceptedInvitation } : m)})
  }

  return (
    <Box>
      <Article article={article} />
      {stateHousehold.members.map((member, index) =>
        <div key={index} className={style.person}>
          <p>{member.displayName}</p>
          <Switch checked={member.acceptedInvitation} onChange={e => handleChange(member)} />
        </div>
      )}
    </Box>
  )
}

const Guests: React.FC<GuestsProps> = ({ household, setHousehold, article }: GuestsProps): JSX.Element => {
  const [stateHousehold, setStateHoushold] = React.useState<updateHouseholdProps>({ ...household, updated: false })

  const callFunction = React.useCallback(() => updateHousehold(stateHousehold), [stateHousehold])
  const { } = useApi<void>({ callFunction, initialValue: undefined })

  const [displayName, setGuest] = React.useState('');

  const handleChange = (event: any) => {
    setGuest(event.target.value)
  }

  const handleAdd = () => {
    if (!stateHousehold.guests)
      stateHousehold.guests = new Array
    stateHousehold.guests?.push({ displayName })
    setStateHoushold({ ...stateHousehold, updated: true })
    setHousehold(stateHousehold)
  }

  const handleRemove = (displayName: string) => {
    stateHousehold.guests = stateHousehold.guests?.filter(guest => guest.displayName !== displayName)
    setStateHoushold({ ...stateHousehold, updated: true })
    setHousehold(stateHousehold)
  }

  return (
    <Box>
      <Article article={article} />
      {stateHousehold.guests?.map((guest, index) =>
        <div key={index} className={style.person}>
          <p>{guest.displayName}</p>
          {/* <FontAwesomeIcon className={style.icon} icon={faTrash} onClick={e => handleRemove(guest.displayName)} /> */}
        </div>
      )}
      {/* {household.maxGuests ? (stateHousehold.guests ? stateHousehold.guests.length + 1 : 0) <= household.maxGuests &&
        <Form className={style.guests}>
          <Form.Item label="Naam">
            <Input key={stateHousehold?.guests?.length} placeholder="Jan Jansen" className={style.input} onChange={handleChange} />
          </Form.Item>
          <Form.Item>
            <Button className={style.button} type="submit" onClick={handleAdd}>Toevoegen</Button>
          </Form.Item>
        </Form>
        : null} */}
    </Box>
  )
}

interface Rsvp {
  setHousehold: React.Dispatch<React.SetStateAction<Household>>
  householdId: string
  setHouseholdId: React.Dispatch<React.SetStateAction<Household>>
}

const pageId = "3EyeAbvSGhDkYwJ2F25udw"

export const Rsvp: React.FC<Rsvp> = ({ setHousehold, householdId, setHouseholdId }: Rsvp): JSX.Element => {
  const household = React.useContext(Context)
  const [householdCode, setHouseholdCode] = React.useState('household-code');
  const [householdCodeSubmited, setHouseholdCodeSubmited] = React.useState(false)

  const callHousehold = React.useCallback(() => getHouseholdUsingCode({ code: householdCode.toLocaleUpperCase(), submitted: householdCodeSubmited }), [householdCode, householdCodeSubmited])
  const { running, data: householdFound } = useApi<Household | null>({ callFunction: callHousehold, initialValue: null })

  const handleChange = (event: any) => {
    setHouseholdCodeSubmited(false)
    setHouseholdCode(event.target.value)
  }

  React.useEffect(() => {
    if (householdFound && householdCodeSubmited) {
      setHousehold(householdFound)
      setHouseholdId(householdFound._id)
      setHouseholdCodeSubmited(false)
    }
  }, [householdFound, householdCodeSubmited])

  const callPage = React.useCallback(() => getPage({ pageId }), [])
  const { data: page } = useApi({ callFunction: callPage, initialValue: null })

  page?.fields.background ? setBackground(page.fields.background.fields.file.url) : null

  if (householdCodeSubmited && running) {
    return <Loading message={i18n.t('app:loading')} />
  }

  if (!householdId) {
    return (
      <Grid container>
        <Grid item xs={12} md={8} lg={9} className={style.image} />
        <Grid item xs={12} md={4} lg={3} className={style.content}>
          <div className={style.envelop} >
            <img src="/assets/envelop.svg" />
          </div>
          <div className={style.form}>
            <h1>RSVP: Laat weten of je er bij bent!</h1>
            <p className={style.grey}>
              Voer de code in die op de trouwkaart staat die je hebt ontvangen.
            </p>
            {householdCodeSubmited && !household ? <Alert className={style.error} severity="error">Niet gevonden.</Alert> : null}
            <Input placeholder="XDQF" className={style.code} name="householdCode" onChange={handleChange} />
            <br />
            <Button className={style.button} size="large" onClick={() => setHouseholdCodeSubmited(true)}>
              Doorgaan
            </Button>
          </div>
        </Grid>
      </Grid>
    )
  } else if (household._id) {
    return (
      <Box className={style.content}>
        <BackButton />
        <h1>{page?.fields.header}</h1>
        <h2 className={style.household}>{household.displayName}</h2>
        {page?.fields.introduction ? (<ReactMarkdown>{page?.fields.introduction}</ReactMarkdown>) : null}
        {page?.fields.articles ? <Members household={household} setHousehold={setHousehold} article={page.fields.articles[0].fields} /> : null}
        {household.maxGuests && household.maxGuests != 0 && page?.fields.articles ? <Guests household={household} setHousehold={setHousehold} article={page.fields.articles[1].fields} /> : null}
        {page?.fields.articles ? <Article article={page.fields.articles[2].fields} /> : null}
      </Box>
    )
  }
  else {
    return (
      <Loading message={i18n.t('app:loading')} />
    )
  }
}