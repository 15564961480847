import React from 'react'
import i18n from 'i18next'
import { history } from '@wedding-site/client/utils/history'
import { useLocalStorage } from '@wedding-site/client/utils/local-storage'
import { Route, useLocation } from 'react-router-dom'
import { initializeRealmApp } from '@wedding-site/client/apis/base-client'
import { Loading } from '@wedding-site/client/components/shared/ui-loading'
import { Box } from '@material-ui/core'
import { BSON } from 'mongodb-stitch-browser-sdk'
import { useApi } from '@wedding-site/client/components/shared/feat-api'
import { Household } from '@wedding-site/common/types/households'
import { getHousehold } from '@wedding-site/client/apis/realm/households'
import { Context } from '@wedding-site/client/components/shared/feat-context'

import { Contacts } from '@wedding-site/client/components/feat-contact'
import { Crew } from '@wedding-site/client/components/feat-crew'
import { Photos } from '@wedding-site/client/components/feat-photos'
import { Home } from '@wedding-site/client/components/feat-home'
import { Locations } from '@wedding-site/client/components/feat-locations'
import { Menu } from '@wedding-site/client/components/feat-menu'
import { NavBar } from '@wedding-site/client/components/feat-navigation'
import { OurStory } from '@wedding-site/client/components/feat-our-story'
import { Program } from '@wedding-site/client/components/feat-program'
import { Rsvp } from '@wedding-site/client/components/feat-rsvp'
import { WeddingCode } from 'libs/client/components/feat-wedding-code/src'
import style from './style.module.sass'

export const App: React.FC = (): JSX.Element => {
  const [realmReady, setRealmReady] = React.useState(false)

  React.useEffect((): void => {
    const loginToRealm = async (): Promise<void> => {
      try {
        // only do this once
        // setCurrentApp(FrontendApp.Rsvp)
        await initializeRealmApp()
        setRealmReady(true)
      } catch (err) {
        console.log(err)
      }
    }
    if (!realmReady) {
      loginToRealm()
    }
  }, [realmReady])

  if (!realmReady)
    <Loading message={i18n.t('app:loading')} />

  const [household, setHousehold] = React.useState({} as Household)
  const [householdId, setHouseholdId] = useLocalStorage('household-id')

  const search = useLocation().search;
  const searchValue = new URLSearchParams(search).get('household-id') || null

  const callHousehold = React.useCallback(() => getHousehold({ _id: new BSON.ObjectId(searchValue ? searchValue : householdId) }), [searchValue])
  const { running, data } = useApi<Household | null>({ callFunction: callHousehold, initialValue: null })
  
  React.useEffect(() => {
    if (searchValue && !data)
      setHouseholdId(false)

    if (data)
      setHousehold(data)
      if (data?._id != undefined)
        setHouseholdId(data?._id)
        
    history.push({
      pathname: window.location.pathname
    })
  }, [searchValue, data])

  if (running)
    <Loading message={i18n.t('app:loading')} />

  return (
    <Box>
      <Box className={style.pages}>
        <Context.Provider value={household}>
          <Route exact path="/" component={Home} />
          <Route path="/contact" component={Contacts} />
          <Route path="/crew" component={Crew} />
          <Route path="/photos" component={Photos} />
          <Route path="/locations" component={Locations} />
          <Route path="/more" component={Menu} />
          <Route path="/our-story" component={OurStory} />
          <Route path="/program" component={Program} />
          <Route path="/rsvp" render={() => <Rsvp setHousehold={setHousehold} householdId={householdId} setHouseholdId={setHouseholdId} /> } />
          <Route path="/wedding-code" component={WeddingCode} />
        </Context.Provider>
      </Box>
      <NavBar />
    </Box>
  )
}
