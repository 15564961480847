import {find, findOne, findOneAndUpdate} from '@wedding-site/client/apis/base-client'
import {DatabaseName, DbCollections} from '@wedding-site/common/database/types'
import {Household} from '@wedding-site/common/types/households'
import {BSON} from 'mongodb-stitch-browser-sdk'

interface getHousehold {
	_id?: BSON.ObjectId
	code?: string
}

export interface updateHouseholdProps extends Household {
	updated: boolean
}

export const getHouseholds = async (): Promise<Household[]> =>
  find<Household>({dbName: DatabaseName.Wedding, col: DbCollections.Households, query: {}})

export const getHousehold = async ({_id, code}: getHousehold): Promise<Household | null> =>
  findOne<Household | null>({dbName: DatabaseName.Wedding, col: DbCollections.Households, query: {$or: [{_id}, {code}]}})

export const saveHousehold = async (household: Household): Promise<Household | null> => 
  findOneAndUpdate<Household>({dbName: DatabaseName.Wedding, col: DbCollections.Households, query: {_id: household._id}, update:  household})

export const updateHousehold = async (household: updateHouseholdProps): Promise<void> => {
	if(household.updated) {
		const {updated, ...rest} = household
		await saveHousehold(rest)
	}	
}