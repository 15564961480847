import React from 'react'
import { Link } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'
import { Box } from '@material-ui/core'
import { BackButton } from '@wedding-site/client/components/feat-navigation'
import { Context } from '@wedding-site/client/components/shared/feat-context'
import { useApi } from '@wedding-site/client/components/shared/feat-api'
import { getPage } from '@wedding-site/client/apis/contentful/pages'
import { Member } from '@wedding-site/common/types/persons'
import { getMembers } from '@wedding-site/client/apis/contentful/members'
import i18n from 'i18next'
import { Loading } from '@wedding-site/client/components/shared/ui-loading'
import { Article } from '@wedding-site/client/components/shared/feat-article'
import style from './style.module.sass'

const pageId = "2TRNTGmrRhu6pcCknVZg0w"

const query = {
  tags: ['contact'],
  select: [
    'fields.displayName',
    'fields.phoneNumber'
  ],
  orderBy: 'fields.orderId'
}

const Contact: React.FC<Member> = (contact): JSX.Element => {
  const household = React.useContext(Context)
  return (
    <div>
      <p className={style.name}>{contact.displayName}</p>
      {household._id ? (
        <p className={style.details}>
          Mobiel: <a href={"tel:" + contact.phoneNumber}>{contact.phoneNumber}</a>
        </p>
      ) : (
        <p className={style.details}>
          Meld aan met de (QR) <Link to="/rsvp">code</Link> voor het mobiel nummer.
        </p>
      )}
    </div>
  )
}

export const Contacts: React.FC = (): JSX.Element => {
  const callPage = React.useCallback(() => getPage({ pageId }), [])
  const { running, data: page } = useApi({ callFunction: callPage, initialValue: null })

  const callMembers = React.useCallback(() => getMembers(query), [])
  const { data: contacts } = useApi({ callFunction: callMembers, initialValue: null })

  if (running)
    <Loading message={i18n.t('app:loading')} />

  return (
    <Box className={style.content}>
      <BackButton />
      <h1>{page?.fields.header}</h1>
      {page?.fields.introduction ? (<ReactMarkdown>{page?.fields.introduction}</ReactMarkdown>) : null}
      {page?.fields.articles ? <Article article={page.fields.articles[0].fields} /> : null}
      {contacts?.items.map((item, index) => <Contact key={index} {...item.fields} />)}
    </Box>
  )
}