import {count} from './realm/count'
import {deleteMany, deleteOne} from './realm/delete'
import {find, findOne, findOneAndUpdate} from './realm/find'
import {callFunction} from './realm/function'
import {initializeRealmApp} from './realm/init'
import {insertMany, insertOne} from './realm/insert'
import {updateMany, updateOne} from './realm/update'
import {watch} from './realm/watch'

export {
  initializeRealmApp,
  count,
  deleteMany,
  deleteOne,
  find,
  findOne,
  findOneAndUpdate,
  callFunction,
  insertMany,
  insertOne,
  updateMany,
  updateOne,
  watch,
}
