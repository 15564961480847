import * as Contentful from 'contentful'

export const contentfulClient = Contentful.createClient({
  // This is the space ID. A space is like a project folder in Contentful terms
  space: "taxcqwn24e6v",
  // This is the access token for this space. Normally you get both ID and the token in the Contentful web app
  accessToken: "m5769G0WWew-XJqqVQRdS-WfQgfEsidlAlGgnQcOdlU"
})

export interface getEntry {

}

export interface Entry<T> extends Contentful.Entry<T>{

}

export interface getEntries {
  tags?: string[],
  select?: string[]
  orderBy?: string
}