import { useEffect, useState } from "react"

export const useLocalStorage = (storageKey: string, fallbackState = false) => {
    const [value, setValue] = useState(
        // @ts-ignore
        JSON.parse(localStorage.getItem(storageKey) ) ?? fallbackState
    )

    useEffect(() => {
        localStorage.setItem(storageKey, JSON.stringify(value))
    }, [value, storageKey])

    return [value, setValue]
}