import {DatabaseName, DbCollections} from '@wedding-site/common/database/types'
import {Filter, UpdateFilter} from 'mongodb'
import {StitchClientErrorCode} from 'mongodb-stitch-browser-sdk'
import {RemoteUpdateOptions} from 'mongodb-stitch-core-services-mongodb-remote'
import {initializeRealmApp} from './init'

interface UpdateOneParams<T> {
  dbName: DatabaseName
  col: DbCollections
  query: Filter<T>
  update: UpdateFilter<T>
  updateOptions?: RemoteUpdateOptions | undefined
}
export const updateOne = async <T>({dbName, col, query, update, updateOptions}: UpdateOneParams<T>): Promise<void> => {
  const {mongoDb} = await initializeRealmApp()
  try {
    await mongoDb.db(dbName).collection<T>(col).updateOne(query, update, updateOptions)
  } catch (err) {
    if (err.code === StitchClientErrorCode.MustAuthenticateFirst) {
      await mongoDb.db(dbName).collection<T>(col).updateOne(query, update, updateOptions)
    } else {
      throw err
    }
  }
}

interface UpdateManyParams<T> {
  dbName: DatabaseName
  col: DbCollections
  query: Filter<T>
  update: UpdateFilter<T>
  updateOptions?: RemoteUpdateOptions | undefined
}
export const updateMany = async <T>({dbName, col, query, update, updateOptions}: UpdateManyParams<T>): Promise<void> => {
  const {mongoDb} = await initializeRealmApp()
  try {
    await mongoDb.db(dbName).collection<T>(col).updateMany(query, update, updateOptions)
  } catch (err) {
    if (err.code === StitchClientErrorCode.MustAuthenticateFirst) {
      await mongoDb.db(dbName).collection<T>(col).updateMany(query, update, updateOptions)
    } else {
      throw err
    }
  }
}
