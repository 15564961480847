import {DatabaseName, DbCollections} from '@wedding-site/common/database/types'
import {Filter, UpdateFilter} from 'mongodb'
import {StitchClientErrorCode} from 'mongodb-stitch-browser-sdk'
import {RemoteFindOptions, RemoteFindOneAndModifyOptions} from 'mongodb-stitch-core-services-mongodb-remote'
import {initializeRealmApp} from './init'

interface FindParams<T> {
  dbName: DatabaseName
  col: DbCollections
  query: Filter<T>
  options?: RemoteFindOptions | undefined
}
export const find = async <T>({col, dbName, options, query}: FindParams<T>): Promise<T[]> => {
  const {mongoDb} = await initializeRealmApp()
  try {
    const data = await mongoDb.db(dbName).collection<T>(col).find(query, options).toArray()
    return data
  } catch (err) {
    if (err.code === StitchClientErrorCode.MustAuthenticateFirst) {
      const data = await mongoDb.db(dbName).collection<T>(col).find(query, options).toArray()
      return data
    }
    throw err
  }
}

interface FindOneParams<T> {
  dbName: DatabaseName
  col: DbCollections
  query: Filter<T>
  options?: RemoteFindOptions | undefined
}
export const findOne = async <T>({col, dbName, options, query}: FindOneParams<T>): Promise<T | null> => {
  const {mongoDb} = await initializeRealmApp()
  try {
    const data = await mongoDb.db(dbName).collection<T>(col).findOne(query, options)
    return data
  } catch (err) {
    if (err.code === StitchClientErrorCode.MustAuthenticateFirst) {
      const data = await mongoDb.db(dbName).collection<T>(col).findOne(query, options)
      return data
    }
    throw err
  }
}

interface FindOneAndUpdateParams<T> {
  dbName: DatabaseName
  col: DbCollections
  query: Filter<T>
  update: UpdateFilter<T>
  options?: RemoteFindOneAndModifyOptions | undefined
}
export const findOneAndUpdate = async <T>({col, dbName, options, query, update}: FindOneAndUpdateParams<T>): Promise<T | null> => {
  const {mongoDb} = await initializeRealmApp()
  try {
    const data = await mongoDb.db(dbName).collection<T>(col).findOneAndUpdate(query, update, options)
    return data
  } catch (err) {
    if (err.code === StitchClientErrorCode.MustAuthenticateFirst) {
      const data = await mongoDb.db(dbName).collection<T>(col).findOneAndUpdate(query, update, options)
      return data
    }
    throw err
  }
}
