import {DatabaseName, DbCollections} from '@wedding-site/common/database/types'
import {StitchClientErrorCode, Stream} from 'mongodb-stitch-browser-sdk'
import {ChangeEvent} from 'mongodb-stitch-core-services-mongodb-remote'
import {initializeRealmApp} from './init'

interface WatchParams {
  dbName: DatabaseName
  col: DbCollections
  // eslint-disable-next-line @typescript-eslint/ban-types
  arg?: object
}
export const watch = async <T>({dbName, col, arg}: WatchParams): Promise<Stream<ChangeEvent<T>>> => {
  const {mongoDb} = await initializeRealmApp()
  try {
    const stream = await mongoDb.db(dbName).collection<T>(col).watch(arg)
    return stream
  } catch (err) {
    if (err.code === StitchClientErrorCode.MustAuthenticateFirst) {
      const stream = await mongoDb.db(dbName).collection<T>(col).watch(arg)
      return stream
    }
    throw err
  }
}
