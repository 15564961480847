// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".style_spinner__8jZcx svg {\n  color: #7D957D !important;\n}\n\n.style_loading__3oBZL {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n}\n\n.style_loadingText__3bjMG {\n  margin-top: 2rem;\n}", ""]);
// Exports
exports.locals = {
	"spinner": "style_spinner__8jZcx",
	"loading": "style_loading__3oBZL",
	"loadingText": "style_loadingText__3bjMG"
};
module.exports = exports;
