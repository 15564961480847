import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Box } from '@material-ui/core'
import { BackButton } from '@wedding-site/client/components/feat-navigation'
import { useApi } from '@wedding-site/client/components/shared/feat-api'
import { getPage } from '@wedding-site/client/apis/contentful/pages'
import { Member as MemberProps } from '@wedding-site/common/types/persons'
import { getMembers } from '@wedding-site/client/apis/contentful/members'
import i18n from 'i18next'
import { Loading } from '@wedding-site/client/components/shared/ui-loading'
import { Article } from '@wedding-site/client/components/shared/feat-article'
import style from './style.module.sass'

const pageId = "7iUh2DFMLs6v8tyLtawyWR"

const query = {
  select: [
    'fields.displayName',
    'fields.role',
    'fields.photo',
    'fields.article'
  ],
  orderBy: 'fields.orderId'
}

const Member: React.FC<MemberProps> = (member): JSX.Element => (
  <div className={style.member}>
    <div className={style.photo}>
      <img src={member.photo.fields.file.url} />
    </div>
    <div className={style.role}>
      <p>
        <b>{member.displayName}</b><i> - {member.role}</i>
      </p>
    </div>
    {member.article ? (<Article header={false} article={member.article.fields} />) : null}
  </div>
)

export const Crew: React.FC = (): JSX.Element => {
  const callPage = React.useCallback(() => getPage({ pageId }), [])
  const { running, data: page } = useApi({ callFunction: callPage, initialValue: null })

  const callMembers = React.useCallback(() => getMembers(query), [])
  const { data: members } = useApi({ callFunction: callMembers, initialValue: null })

  if (running)
    <Loading message={i18n.t('app:loading')} />

  return (
    <Box className={style.content}>
      <BackButton />
      <h1>{page?.fields.header}</h1>
      {page?.fields.introduction ? (<ReactMarkdown>{page?.fields.introduction}</ReactMarkdown>) : null}
      {members?.items.map((member, index) => (<Member key={index} {...member.fields} />))}
    </Box>
  )
}