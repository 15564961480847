import i18n, {TFunction} from 'i18next'
import {nl} from './nl'

export const locales = {nl}

export const setupLocales = async (): Promise<TFunction> =>
  i18n.init({
    lng: 'nl',
    fallbackLng: 'nl',
    resources: locales,
  })
