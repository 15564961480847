// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".style_photo__2NnOJ {\n  width: 100%;\n  height: 12.5rem;\n  object-fit: cover;\n  border-radius: 0.25rem;\n}", ""]);
// Exports
exports.locals = {
	"photo": "style_photo__2NnOJ"
};
module.exports = exports;
