import ReactMarkdown from 'react-markdown'
import { Article as ArticleType } from '@wedding-site/common/types/articles'
import style from './style.module.sass'

interface Article {
  article: ArticleType
  header?: boolean
}

export const Article: React.FC<Article> = ({ article, header = true }: Article): JSX.Element => (
  <div>
    {header ? <h2>{article.header}</h2> : null}
    {article.image ? <img className={style.photo} src={article.image.fields.file.url} /> : null}
    {article.body ? <ReactMarkdown>{article.body}</ReactMarkdown> : null}
  </div>
)