// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "blockquote {\n  color: rgba(65, 63, 62, 0.58);\n}\n\np,\nli {\n  font-weight: 400;\n  font-size: 1rem;\n  line-height: 1.6rem;\n}\np b,\np strong,\nli b,\nli strong {\n  font-weight: 600;\n}\np.style_grey__TkHjl,\nli.style_grey__TkHjl {\n  color: rgba(65, 63, 62, 0.58);\n}\np.style_name__HoG0N,\nli.style_name__HoG0N {\n  margin-bottom: 0.125rem;\n  font-weight: 500;\n}\np.style_details__2WAAk,\nli.style_details__2WAAk {\n  font-size: 0.85rem;\n  line-height: 1.275rem;\n  color: rgba(65, 63, 62, 0.58);\n}\np.style_details__2WAAk a,\nli.style_details__2WAAk a {\n  color: #7D957D !important;\n  text-decoration: unset !important;\n}\np a,\nli a {\n  color: #7D957D !important;\n  font-weight: 600;\n  text-decoration: underline !important;\n}\n\nol,\nul {\n  margin-left: -1rem;\n}\n\nul {\n  list-style-type: circle;\n}\n\nh1 {\n  font-weight: 700;\n  font-size: 2rem;\n  line-height: 2.658rem;\n  color: #413f3e;\n}\n\nh2 {\n  font-weight: 600;\n  font-size: 1.313rem;\n  line-height: 2rem;\n  color: #413f3e;\n  margin-bottom: 0;\n}\n\n.style_button__2LDul {\n  height: 3rem;\n  font-family: \"Yaldevi Colombo\" !important;\n  font-weight: 700 !important;\n  letter-spacing: 0em;\n  text-align: center;\n  border-radius: 0.25rem;\n  text-transform: unset !important;\n  font-size: 1rem;\n  line-height: 1.5rem;\n}\n\n.style_content__3Cm8- {\n  padding: 1.25rem 1.563rem;\n  margin: auto;\n}\n\n@media screen and (min-width: 600px) {\n  .style_content__3Cm8- {\n    max-width: 43.75rem !important;\n  }\n\n  .style_image__38_TQ {\n    height: 25rem;\n  }\n}", ""]);
// Exports
exports.locals = {
	"grey": "style_grey__TkHjl",
	"name": "style_name__HoG0N",
	"details": "style_details__2WAAk",
	"button": "style_button__2LDul",
	"content": "style_content__3Cm8-",
	"image": "style_image__38_TQ"
};
module.exports = exports;
