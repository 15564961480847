import React from 'react'
import { CircularProgress, Typography } from '@material-ui/core'
import style from './style.module.scss'

interface Loading {
  message?: string
}

export const Loading: React.FC<Loading> = ({ message = 'loading' }: Loading): JSX.Element => (
  <div className={style.loading}>
    <CircularProgress className={style.spinner} size={50} />
    <Typography variant="body2" className={style.loadingText}>
      {message}
    </Typography>
  </div>
)