import React from 'react'
import ReactMarkdown from 'react-markdown'
import cloneDeep from 'clone-deep'
import { Box } from '@material-ui/core'
import Gallery from 'react-photo-gallery'
import { GooglePhoto } from 'react-google-photo'
import { BackButton } from '@wedding-site/client/components/feat-navigation'
import { useApi } from '@wedding-site/client/components/shared/feat-api'
import { getPage } from '@wedding-site/client/apis/contentful/pages'
import { getGalleries } from '@wedding-site/client/apis/contentful/galleries'
import i18n from 'i18next'
import { Loading } from '@wedding-site/client/components/shared/ui-loading'
import { Article } from '@wedding-site/client/components/shared/feat-article'
import 'react-google-photo/styles.css'
import style from './style.module.sass'

const pageId = "2mHourz43sbXHzWlqMnij1"

const photos = new Array

export const Photos: React.FC = (): JSX.Element => {
  const callPage = React.useCallback(() => getPage({ pageId }), [])
  const { running, data: page } = useApi({ callFunction: callPage, initialValue: null })

  const callGalleries = React.useCallback(() => getGalleries({}), [])
  const { data: galleries } = useApi({ callFunction: callGalleries, initialValue: null })

  const photos = new Array  

  galleries?.items.map((item) => item.fields.photos.map((photo) => photos.push(
    {
      src: photo.fields.file.url,
      width: photo.fields.file.details.image?.width,
      height: photo.fields.file.details.image?.height
    }
  )))

  if (running)
    <Loading message={i18n.t('app:loading')} />

  const [open, setOpen] = React.useState(false)
  const [index, setIndex] = React.useState(0)
  const galleryPhotos = cloneDeep(photos)
  return (
    <Box className={style.content}>
      <BackButton />
      <h1>{page?.fields.header}</h1>
      {page?.fields.introduction ? (
        <ReactMarkdown>
          {page.fields.introduction}
        </ReactMarkdown>
      ) : null}
      <Gallery
        photos={galleryPhotos}
        onClick={(_, data) => {
          setIndex(data.index)
          setOpen(true)
        }}
        targetRowHeight={250}
      />
      {photos.length != 0 ?
        <GooglePhoto
          open={open}
          src={photos}
          srcIndex={index}
          onChangeIndex={newIndex => setIndex(newIndex)}
          onClose={() => setOpen(false)}
        /> : null}
      <br />
      {page?.fields.articles?.map((article, index) => <Article key={index} article={article.fields} />)}
    </Box>
  )
}