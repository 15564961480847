import i18n from 'i18next'
import * as React from 'react'
import { history } from '@wedding-site/client/utils/history'
import './style.css'

// This component will catch all JavaScript errors
// When this happens, we show an error view which lets the user restart the application
// To make sure this component will always properly render in case of an error,
// we do not use any modules or other components here.

// NOTE: This component is still written as a class, because there are no hooks
// available yet for `componentDidCatch`
// See: https://reactjs.org/docs/hooks-faq.html#do-hooks-cover-all-use-cases-for-classes
interface AppProps {
  children: JSX.Element
}

interface AppState {
  error: Error | null
}

export class ErrorBoundary extends React.PureComponent<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props)

    this.state = { error: null }
  }

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    this.setState({ error })
    // withScope((scope: Scope): void => {
    //   scope.setExtras({errorInfo})
    //   captureException(error)
    // })
  }

  private handleRestart = (): void => {
    history.push('/')
    window.location.reload()
  }

  public render(): JSX.Element {
    const { children } = this.props
    const { error } = this.state

    if (error) {
      return (
        <div style={{ textAlign: 'center' }}>
          <div style={{ display: 'inline-block' }}>
            <h1>{i18n.t('errorboundary:title')}</h1>
            <div>{i18n.t('errorboundary:body')}</div>
            <div className="error-message">{error.message}</div>
            <br />
            <button type="button" className="restart-button" onClick={this.handleRestart}>
              {i18n.t('errorboundary:tryAgain')}
            </button>
          </div>
        </div>
      )
    }
    return children
  }
}
