import { contentfulClient, getEntry, getEntries } from '@wedding-site/client/apis/contentful/client'
import { Gallery } from '@wedding-site/common/types/galleries'

interface getGallery extends getEntry {
    galleryId: string
}

export const getGallery = ({ galleryId }: getGallery) => contentfulClient.getEntry<Gallery>(galleryId)

interface getGalleries extends getEntries {
    galleryIds?: string[]
}

export const getGalleries = ({ tags, galleryIds: entryIds, select, orderBy }: getGalleries) => contentfulClient.getEntries<Gallery>({
    content_type: 'gallery',
    'metadata.tags.sys.id[in]': tags?.join(),
    'sys.id[in]': entryIds?.join(),
    select: select,
    order: orderBy
})