import { contentfulClient, getEntry, getEntries } from '@wedding-site/client/apis/contentful/client'
import { Member } from '@wedding-site/common/types/persons'

interface getMember extends getEntry {
    memberId: string
}

export const getMember = ({ memberId }: getMember) => contentfulClient.getEntry<Member>(memberId)

interface getMembers extends getEntries {
    memberIds?: string[]
}

export const getMembers = ({ tags, memberIds: entryIds, select, orderBy }: getMembers) => contentfulClient.getEntries<Member>({
    content_type: 'member',
    'metadata.tags.sys.id[in]': tags?.join(),
    'sys.id[in]': entryIds?.join(),
    select: select,
    order: orderBy
})