import React from 'react'
import { Link as ReactLink, useLocation } from 'react-router-dom'
import { Button } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import style from './style.module.sass'
import { history } from '@wedding-site/client/utils/history'
import { LinkProps } from './types'
import { links } from './data'

export const BackButton: React.FC = (): JSX.Element => (
  <Button className={style.button} onClick={history.goBack}>
    <FontAwesomeIcon className={style.icon} icon={faArrowLeft} />&nbsp;Vorige
  </Button>
)

const Link: React.FC<LinkProps> = ({ name, target, icon, location }): JSX.Element => (
  <ReactLink to={target}>
    <div className={style.navbarItem}>
      <div className={style.icon}>
        <FontAwesomeIcon className={location.pathname === target ? style.active : style.inactive} icon={icon} />
      </div>
      <p>{name}</p>
    </div>
  </ReactLink>
)

export const NavBar: React.FC = (): JSX.Element => {
  const location = useLocation()
  return (
    <div className={style.navbar}>
      <div className={style.container}>
        {links.map((link, index) => <Link key={index} {...link} location={location} />)}
      </div>
    </div>
  )
}
