import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Steps, Tabs } from 'antd'
import { Grid, Hidden } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import i18n from 'i18next'
import { Loading } from '@wedding-site/client/components/shared/ui-loading'
import { Context } from '@wedding-site/client/components/shared/feat-context'
import { useApi } from '@wedding-site/client/components/shared/feat-api'
import { Household } from '@wedding-site/common/types/households'
import { getProgram } from '@wedding-site/client/apis/contentful/pages'
import { setBackground } from '@wedding-site/client/utils/set-background'
import { Entry } from 'contentful'
import { Shift } from '@wedding-site/common/types/shifts'
import style from './style.module.sass'

const programId = "70Ew8nfJOsm3EBe1NsDjX4"

const config = {
  shifts: 2,
  gt: {
    type: 'Je bent een Dag-gast',
    description: 'The premium wedding day experience!'
  },
  le: {
    type: 'Je bent een Feest-gast',
    description: 'The wedding party experience!'
  },
  introdution: {
    private: 'Zo ziet onze dag met jou er uit.',
    public: 'Zo ziet de dag er uit.'
  }
}

interface Banner {
  shifts: string[]
}

const Banner: React.FC<Banner> = ({ shifts }: Banner): JSX.Element => (
  <Grid xs={12} className={style.banner}>
    <FontAwesomeIcon className={style.star} icon={faStar} />
    <div className={style.detail}>
      <div className={style.type}>
        {shifts.length > config.shifts ? config.gt.type : config.le.type}
      </div>
      <div className={style.description}>
        {shifts.length > config.shifts ? config.gt.description : config.le.description}
      </div>
    </div>
  </Grid>
)

interface Shifts {
  shifts: Entry<Shift>[]
  household?: Household
}

const Shifts: React.FC<Shifts> = ({ shifts, household }: Shifts): JSX.Element => {
  const { Step } = Steps

  if (household)
    shifts = shifts.filter(shift => household.shifts.find(scope => scope === shift.sys.id))

  let currentLocation = ""
  let displayLocation = true

  const step = (shift: any, key: number) => {
    if (currentLocation == shift.fields.location.sys.id) { displayLocation = false }
    else { currentLocation = shift.fields.location.sys.id; displayLocation = true }

    return (
      <div className={style.step}>
        <p className={style.time}>
          {shift.fields.startingTime.substring(11, 16) + " - " + shift.fields.endingTime.substring(11, 16)}
          {shift.fields.entranceTime && key == 0 ? (<span className={style.entranceTime}>ontvangst vanaf: {shift.fields.entranceTime?.substring(11, 16)}</span>) : null}
        </p>
        <p className={style.name}>{shift.fields.header}</p>
        <div className={style.details}>
          <ReactMarkdown>{shift.fields.body}</ReactMarkdown>
          {displayLocation ? (
            <div className={style.location}>
              <img src="/assets/map-pin.svg" /> {shift.fields.location.fields.displayName}
            </div>
          ) : null}
        </div>
      </div>
    )
  }

  return (
    <Steps className={style.steps} direction="vertical">
      {shifts?.map((shift, index) => (
        <Step key={index}
          title={step(shift, household ? index : 0)}
          icon={
            <img
              src={shift.fields.image?.fields.file.url}
              className={style.icon}
            />
          }
        />
      ))}
    </Steps>
  )
}

export const Program: React.FC = (): JSX.Element => {
  const household = React.useContext(Context)

  const callProgram = React.useCallback(() => getProgram({ programId }), [])
  const { running, data: page } = useApi({ callFunction: callProgram, initialValue: null })

  if (running)
    <Loading message={i18n.t('app:loading')} />

  const { TabPane } = Tabs

  page?.fields.background ? setBackground(page.fields.background.fields.file.url) : null
  return (
    <Grid container>
      <Hidden mdUp>
        {household._id ? <Banner shifts={household.shifts} /> : null}
      </Hidden>
      <Grid item xs={12} md={8} lg={9} className={style.image} />
      {page ? (
        <Grid item xs={12} md={4} lg={3} className={style.content}>
          <h1>{page.fields.header}</h1>
          <p>
            <b>{household._id ? config.introdution.private : config.introdution.public}</b>
          </p>
          <Hidden smDown>
            {household._id ? <Banner shifts={household.shifts} /> : null}
          </Hidden>
          {page.fields.shifts ?
            household._id ? (
              <Tabs className={style.tabs} defaultActiveKey="2">
                <TabPane tab="Bruidspaar" key="1">
                  <Shifts shifts={page.fields.shifts} />
                </TabPane>
                <TabPane tab="Jouw programma" key="2">
                  <Shifts shifts={page.fields.shifts} household={household} />
                </TabPane>
              </Tabs>
            ) : (
              <Shifts shifts={page.fields.shifts} />
            ) : null}
        </Grid>
      ) : null}
    </Grid>
  )
}
