import React from 'react'
import GoogleMapReact from 'google-map-react'
import { Box } from '@material-ui/core'
import style from './style.module.sass'

interface Marker {
    displayName: string
    lat: number
    lng: number
}

const Marker: React.FC<Marker> = ({ displayName }): JSX.Element => (
    <Box className={style.wrapper} >
        <Box className={style.icon}>
            <img src="/assets/map-pin.svg" />
        </Box>
        <p>{displayName}</p>
    </Box>
)

interface GoogleMap {
    height: string
    width: string
    center: {
        lat: number
        lng: number
    }
    zoom: number
    markers: Marker[]
}

export const GoogleMap: React.FC<GoogleMap> = ({ height, width, center, zoom, markers }): JSX.Element => {

    const options = {
        fullscreenControl: false,
        zoomControl: false,
        draggable: false,
        keyboardShortcuts: false
    }

    return (
        <Box sx={{ height: height, width: width }}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: "AIzaSyCsaK8uUdS9j9eW84QHth6QuB1ubRsIdqk" }}
                defaultCenter={center}
                defaultZoom={zoom}
                yesIWantToUseGoogleMapApiInternals
                options={options}
            >
                {markers.map((marker, index) => <Marker key={index} lat={marker.lat} lng={marker.lng} displayName={marker.displayName} />)}
            </GoogleMapReact>
        </Box>
    )
}