import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Grid, Box } from '@material-ui/core'
import { BackButton } from '@wedding-site/client/components/feat-navigation'
import { useApi } from '@wedding-site/client/components/shared/feat-api'
import { getPage } from '@wedding-site/client/apis/contentful/pages'
import i18n from 'i18next'
import { Loading } from '@wedding-site/client/components/shared/ui-loading'
import { Article } from '@wedding-site/client/components/shared/feat-article'
import { getLocations } from '@wedding-site/client/apis/contentful/locations'
import { Location as LocationProps } from '@wedding-site/common/types/locations'
import { GoogleMap } from './google-map'
import style from './style.module.sass'

const pageId = "ZEI4rZ2Pa3svoJyqdGBNT"

const config = {
  center: {
    lat: 52.19938051712903,
    lng: 6.129068761071261
  },
  zoom: 15,
  markers: new Array
}

const query = {
  tags: ['public'],
  select: [
    'fields.displayName',
    'fields.address',
    'fields.coordinates'
  ]
}

const Location: React.FC<LocationProps> = (location): JSX.Element => {
  const address = location.address.split(',')
  return (
    <Grid item xs={12} sm={6}>
      <p className={style.name}>{location.displayName}</p>
      <p className={style.details}>
        Adres: {address[0]}
        <br />
        &emsp;&emsp;&emsp;{address[1].trimLeft()}
      </p>
    </Grid>
  )
}

export const Locations: React.FC = (): JSX.Element => {

  const callPage = React.useCallback(() => getPage({ pageId }), [])
  const { running, data: page } = useApi({ callFunction: callPage, initialValue: null })

  const callLocations = React.useCallback(() => getLocations(query), [])
  const { data: locations } = useApi({ callFunction: callLocations, initialValue: null })

  if (running)
    <Loading message={i18n.t('app:loading')} />

  locations?.items.map((location) => {
    config.markers.push({
      lat: location.fields.coordinates.lat,
      lng: location.fields.coordinates.lon,
      displayName: location.fields.displayName
    })
  })

  locations?.items.sort((a, b) => {
    if (a.fields.displayName < b.fields.displayName) { return -1 }
    if (a.fields.displayName > b.fields.displayName) { return 1 }
    return 0
  })

  return (
    <Box className={style.content}>
      <BackButton />
      <h1>{page?.fields.header}</h1>
      {page?.fields.introduction ? (<ReactMarkdown>{page?.fields.introduction}</ReactMarkdown>) : null}
      <GoogleMap height="21.875rem" width="100%" {...config} />
      <br />
      <Grid container spacing={0}>
        {locations ? locations.items.map((location, index) => <Location key={index} {...location.fields} />) : null}
      </Grid>
      {page?.fields.articles?.map((article, index) => <Article key={index} article={article.fields} />)}
    </Box>
  )
}