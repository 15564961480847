import { contentfulClient, getEntry, getEntries } from '@wedding-site/client/apis/contentful/client'
import { Page, Program } from '@wedding-site/common/types/pages'
import { EntryCollection } from 'contentful'

interface getPage extends getEntry {
    pageId: string
}

export const getPage = ({ pageId }: getPage) => contentfulClient.getEntry<Page>(pageId)

interface getPages extends getEntries {
    pageIds?: string[]
}

export const getPages = ({ tags, pageIds: entryIds, select, orderBy }: getPages) => contentfulClient.getEntries<Page>({
    content_type: 'page',
    'metadata.tags.sys.id[in]': tags?.join(),
    'sys.id[in]': entryIds?.join(),
    select: select,
    order: orderBy
})

interface getProgram extends getEntry {
    programId: string
}

export const getProgram = ({ programId }: getProgram) => contentfulClient.getEntry<Program>(programId, { include: 2 })