import 'antd/dist/antd.css';
import './main.sass';
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'
import { setupLocales } from '@wedding-site/common/locales'
import { history } from '@wedding-site/client/utils/history'
import { scrollToTop } from '@wedding-site/client/utils/scroll-to-top'
import { ErrorBoundary } from '@wedding-site/client/components/feat-errorboundary'
import { App } from '@wedding-site/client/components/feat-app'


const init = async (): Promise<void> => {
  await setupLocales()
  const ScrollToTop = scrollToTop
  ReactDOM.render(
    <ErrorBoundary>
      <Router history={history}>
        <ScrollToTop />
        <App />
      </Router>
    </ErrorBoundary>,
    document.querySelector('#root'),
  )
}

init()
